import { m } from 'framer-motion';

const CatalogPreloader = () => {
    return (
        <m.div
            className="catalog-preloader"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="catalog-prelaoder"
        >
            <div className="catalog-preloader-main-circle">
                <div className="catalog-preloader-small-circle catalog-preloader-small-circle--first" />
                <div className="catalog-preloader-small-circle catalog-preloader-small-circle--second" />
                <div className="catalog-preloader-small-circle catalog-preloader-small-circle--third" />
                <div className="catalog-preloader-medium-circle">
                    <div className="catalog-preloader-medium-circle-inner" />
                </div>
            </div>
        </m.div>
    );
};

export default CatalogPreloader;
